import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import NotFound from "./pages/NotFound";
import News from "./pages/News";
import About from "./pages/About";
import Partners from "./pages/Partners";
import Contact from "./pages/Contact";

const routes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/news' element={<News />} />
                <Route path='/about' element={<About />} />
                <Route path='/partners' element={<Partners />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </BrowserRouter>

    )

}

export default routes