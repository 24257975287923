import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { InfoCard as InfoCardProps } from "../../@types/InfoCard";
import './styles.css'

export default function InfoCard({ image, bgColor, color, altImage, title, subTitle, description,  reverseOrder, children } : InfoCardProps) {
    return (
        <Container fluid className={`bg-${bgColor}`}>
            <Row xs={1} sm={2} className={`py-2 p-sm-5 ${reverseOrder && 'flex-row-reverse'}`}>
                <Col sm={5} className='d-flex justify-content-center'>
                    <img src={image} className="img-fluid rounded" alt={altImage} />
                </Col>
                <Col sm={7} className='app-card d-flex flex-column justify-content-evenly'>
                    <div className='text-center'>
                        <h3 className={`fw-bold text-uppercase text-${color} mt-2`}>
                            {title}
                        </h3>
                        <h5>{subTitle}</h5>
                    </div>
                    <div className='mt-3'>
                        <p className='fs-4 mx-sm-5 mx-1'>
                            {description}
                        </p>
                    </div>
                    {children}
                </Col>
            </Row>
        </Container>
    )
}