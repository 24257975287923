import Service from '../../services/Service';
import { PostsPaginated } from '../../@types/Posts';

const token = process.env.REACT_APP_INSTAGRAM_API_TOKEN
const userId = process.env.REACT_APP_INSTAGRAM_USER_ID

class PostService extends Service{
    static getPosts(){
        return this.Http.get<PostsPaginated>(`https://graph.instagram.com/${userId}/media?access_token=${token}&fields=media_url,permalink,thumbnail_url,timestamp,caption,username,media_type`)
        .then(this.getData)
    }
}

export default PostService;