import React from 'react'
import './styles.css'
import Header from '../Header'
export default function HeaderTicker() {
    return (
        <div className='header-container'>
        <div className="news-container bg-body-tertiary">
            <div className="title">
                Bem-vindo
            </div>
            <ul>
                <li>
                    Seja bem vindo a Uai Grãos!
                </li>

                {/* <li>
                    Lorem ipsum dolor sit
                </li>

                <li>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsam!
                </li> */}
            </ul>
        </div>
        <Header />
        </div>
    )
}
