import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { ProductCard as ProductCardProps } from '../../@types/ProductCard';
import sorgo from '../../assets/sorgo.jpg'
import soja from '../../assets/soja.png'
import milho from '../../assets/milho.png'

export default function ProductCard() {
  const products: ProductCardProps[] = [
    {
      key: '1',
      title: 'Milho',
      description: 'A produção de milho na safra 2020/2021 deve atingir 105,2 milhões de toneladas, maior volume da história, com aumento de 2,6% sobre o ciclo anterior, mesmo com redução de 1,1% na área.',
      alt: 'Milho',
      image: milho,
    },
    {
      key: '2',
      title: 'Sorgo',
      description: 'A safra mineira de grãos 2020/2021 deve ser de 15,6 milhões de toneladas, com crescimento de 1,7% em relação ao volume produzido na safra anterior. Os dados da safra, iniciada no segundo semestre deste ano, fazem parte do 2º Levantamento de Safa de Grãos 2020/2021 da Companhia Nacional de Abastecimento (Conab).',
      alt: 'Sorgo',
      image: sorgo,
    },
    {
      key: '3',
      title: 'Soja',
      description: 'Para 2021, a Abiove projeta uma safra de 132,6 milhões de toneladas, estável ante previsão de novembro. Uma pesquisa com analistas, realizada pela Reuters no início do mês, apontou uma média de 131,79 milhões de toneladas para a colheita que começa em janeiro.',
      alt: 'Soja',
      image: soja,
    },
  ]
  return (
    <Container>
      <Row>
        {products.map((item) => (
          <Col className='d-flex justify-content-around my-5' key={item.key}>
          <div className="card" style={{ width: "18rem" }}>
              <img src={item.image} className="card-img-top" alt={item.alt} />
              <div className="card-body">
                  <h5 className="card-title">{item.title}</h5>
                  <p className="text-dark">{item.description}</p>
              </div>
          </div>
      </Col>
        ))}
      </Row>
    </Container>
  )
}

