import { useEffect, useState } from 'react'
import Layout from '../../layouts/DefaultLayout'
import { Col, Container, Row } from 'react-bootstrap'
import NewsCard from '../../components/NewsCard'
import PostService from './services'
import { PostsPaginated } from '../../@types/Posts'

export default function News() {
  const [posts, setPosts] = useState<PostsPaginated>()
  useEffect(() => {
    PostService.getPosts().then(setPosts);
  }, []);

  return (
    <Layout>
      <Container>
        <p className='fs-2 mt-5 fw-semibold text-center text-uppercase'>
          Notícias
        </p>
        <p className='text-center'>
          Mantenha-se atualizado sobre as principais notícias do setor agrícola seguindo a Uai Grãos em todas as redes sociais!
        </p>
        <Row xs={1} sm={3} className='my-5'>
          {posts?.data?.map((item) =>
            <Col>
              <NewsCard
                media_type={item.media_type}
                caption={item.caption}
                id={item.id}
                key={item.id}
                media_url={item.media_url}
                permalink={item.permalink}
                timestamp={item.timestamp}
                username={item.username}
                thumbnail_url={item.thumbnail_url}
              />
            </Col>
          )}
        </Row>
      </Container>
    </Layout>
  )
}
