import { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import './styles.css'


export default function CarouselComponent() {
  const [index, setIndex] = useState<number>(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  return (
      <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item className='carousel-image bg-img-1'>
        </Carousel.Item>
        <Carousel.Item className='carousel-image bg-img-2'>
        </Carousel.Item>
        <Carousel.Item className='carousel-image bg-img-3'>
        </Carousel.Item>
      </Carousel>
  )
}
