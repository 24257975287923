import DefaultLayout from '../../layouts/DefaultLayout'
import appImg from '../../assets/app_photo.jpg'
import barterImg from '../../assets/barter_photo.jpg'
import apple from '../../assets/apple.png'
import googlePlay from '../../assets/googlePlay.png'
import './styles.css'
import InfoCard from '../../components/InfoCard'
import ProductCard from '../../components/ProductCard'
import Carousel from '../../components/CarouselComponent'

export default function Home() {

  return (
    <DefaultLayout>
      <Carousel />
      <InfoCard
        image={appImg}
        altImage='Aplicativo Uai Grãos'
        description='Com o aplicativo da Uai Grãos, o produtor e o comprador têm maior confiabilidade na hora da negociação. Sistema robusto, seguro, completo, ágil e simples para realizar a transação e comercialização da produção de grãos. Temos a ferramenta de Barter para integrar a Indústria diretamente com os produtores, tirando todos os atravessadores.'
        title='App Uai Grãos'
        reverseOrder={false}
        subTitle='Facilitando a negociação entre produtores e compradores'
        bgColor='success-subtle'
        color='success'
      >
        <div className='mt-5 height-100 d-flex justify-content-between'>
          <a className='d-flex justify-content-center' target='_blank' href='https://play.google.com/store/apps/details?id=com.tiib.uaigraos&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' rel="noreferrer">
            <img
              alt='Disponível no Google Play'
              src={googlePlay}
              className='img-fluid w-75'
            />
          </a>
          <a className='d-flex justify-content-center' target='_blank' href='https://apps.apple.com/app/uai-graos/id6443957570' rel="noreferrer">
            <img
              src={apple}
              alt='Apple App Store'
              className='img-fluid w-75'
            />
          </a>
        </div>
      </InfoCard>

      <ProductCard />
      
      <InfoCard
        image={barterImg}
        altImage='Aplicativo Uai Grãos'
        description='Com a ferramenta de Barter (troca) disponibilizada por meio de nosso inovador aplicativo, estamos transformando a maneira como a indústria e os agricultores interagem e colaboram. Eliminando os intermediários, nosso sistema permite que a indústria se comunique diretamente com os produtores, estabelecendo acordos vantajosos para ambas as partes. Apresentamos uma solução que não apenas agiliza a cadeia de suprimentos agrícolas, mas também fortalece os laços entre a indústria e os agricultores, promovendo um sistema mais justo e colaborativo. Baixe já nosso aplicativo e faça parte dessa transformação no setor agrícola. '
        title='Diferencial'
        reverseOrder={true}
        subTitle='Revolucionando a conexão entre indústria e agricultores'
        bgColor='info-subtle'
        color='primary'
      />
    </DefaultLayout>
  )
}
