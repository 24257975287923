import React from 'react'
import { Card } from 'react-bootstrap'
import './styles.css'
import { PostsData } from '../../@types/Posts';

export default function NewsCard({ caption, id, media_type, media_url, permalink, timestamp, username, thumbnail_url }: PostsData) {
  return (
    
      <a href={permalink} target='_blank' rel="noreferrer">
        <Card className="custom-card small-card">
          <Card.Img variant="top" src={media_type !== 'VIDEO' ? media_url : thumbnail_url} alt="Imagem do Card" className="card-image rounded" />
          <Card.ImgOverlay className="card-overlay">
            <Card.Text className="card-text">{caption?.split('\n')[0]}</Card.Text>
            <Card.Text className="card-text">Clique para mais informações</Card.Text>
          </Card.ImgOverlay>
        </Card>
      </a>
  )
}
