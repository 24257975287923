import { Navbar, Container, Nav, NavLink } from 'react-bootstrap'
import { HeaderItems as HeaderProps } from '../../@types/HeaderItems'
import logo from '../../assets/logo.png'
import './styles.css'
import SocialMediaIcons from '../SocialMediaIcons'
import { Link } from 'react-router-dom'

const headerData: HeaderProps[] = [
  {
    key: '1',
    title: 'Home',
    href: '/'
  },
  {
    key: '2',
    title: 'Notícias',
    href: '/news'
  },
  {
    key: '3',
    title: 'Parceiros/Clientes',
    href: '/partners'
  },
  {
    key: '4',
    title: 'Contato',
    href: '/contact'
  },
  {
    key: '5',
    title: 'Quem somos',
    href: '/about'
  }
]

export default function TopNavbar() {
  return (
    <>
      <Navbar expand="lg" className="navbar p-3 bg-body-tertiary">
        <Container>
          <Link to={'/'}>
            <Navbar.Brand className='brand'>
              <img
                src={logo}
                height={'50vh'}
                className="d-inline-block align-top"
                alt="Uai Grãos logo"
              />
            </Navbar.Brand></Link>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className='ms-auto'>
              {
                headerData.map(
                  (item) => (
                    <NavLink key={item.key}>
                      <Link to={item.href} >
                        <div className='list-item'>
                          {item.title}
                        </div>
                      </Link>
                    </NavLink>
                  )
                )
              }
            </Nav>
            <Nav className='ms-auto'>
              <ul className="list-unstyled list-inline d-flex pt-3">
                <SocialMediaIcons />
              </ul>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}
