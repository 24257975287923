import React from 'react'
import { SocialMedia as SocialMediaProps } from '../../@types/SocialMedia'
import './styles.css'
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa'

export default function SocialMediaIcons() {
    const socialMedia: SocialMediaProps[] = [
        {
            key: '1',
            icon: FaFacebook,
            href: 'https://www.facebook.com/uaigraos',
        },
        {
            key: '2',
            icon: FaInstagram,
            href: 'https://www.instagram.com/uaigraos',
        },
        {
            key: '3',
            icon: FaLinkedin,
            href: 'https://www.linkedin.com/company/uai-gr%C3%A3os',
        },
        {
            key: '4',
            icon: FaYoutube,
            href: 'https://www.youtube.com/?gl=LT&hl=lt',
        }
    ]

    return (
        <div>
            {
                socialMedia.map((item) => (
                    <li key={item.key} className="list-inline-item">
                        <a href={item.href} className="btn-floating btn-sm text-white">
                            <item.icon color='#000' size={23} />
                        </a>
                    </li>
                ))
            }
        </div>
    )
}
